import Cookie from 'js-cookie'

import type { ThemeVariant } from 'app/resources'

import type { SupportedLanguages } from './localization'

export type Cookies = {
	lang: SupportedLanguages
	theme: ThemeVariant
}

const defaultValues: Record<keyof Cookies, Cookie.CookieAttributes> = {
	lang: { expires: 1 },
	theme: { expires: 999 },
}

const setCookie = <K extends keyof Cookies>(name: K, value: Cookies[K], attrs: Cookie.CookieAttributes = {}) => {
	Cookie.set(name, value, { ...defaultValues[name], ...attrs })
}

const removeCookie = (name: keyof Cookies) => {
	Cookie.remove(name)
}

const getCookie = <K extends keyof Cookies>(name: K): Cookies[K] => {
	return Cookie.get(name) as Cookies[K]
}

export const cookiesTyped = {
	getCookie,
	setCookie,
	removeCookie,
}
