import { Navigate } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

import { WithTRPC } from './Wrappers'

const NotFound = {
	path: '*',
	element: <Navigate to="/" />,
}

// https://reactrouter.com/en/main/route/lazy
export const routes: RouteObject[] = [
	{
		path: '/',
		element: <WithTRPC />,
		children: [
			{
				path: '',
				index: true,
				lazy: () => import('./Waitlist'),
			},
			{
				path: 'waitlist-poll',
				lazy: () => import('./WaitlistPoll'),
			},
			{
				path: 'email/unsubscribe',
				lazy: () => import('./Email/Unsubscribe'),
			},
			{
				path: 'email/expired',
				lazy: () => import('./Email/ExpiredLink'),
			},
			NotFound,
		],
	},
	NotFound,
]
