import React, { useContext } from 'react'
import { RouterProvider } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { useSSR, useTranslation } from 'react-i18next'
import { createBrowserRouter } from 'react-router-dom'
import { hydrateRoot, createRoot } from 'react-dom/client'
import * as Tooltip from '@radix-ui/react-tooltip'

import { ThemeProvider, ToastProvider, themeLight, themeDark, isBrowserEnvironment, fn } from '@lensflow/ui'

import { config } from 'app/config'
import { routes } from 'app/routes/config'
import { DehydratedStateContext, ThemeContextProvider, ThemeContext } from 'app/context'

// app level common styles and reset
import 'app/styles/base.less'

// React Toastify
import '@lensflow/ui/index.css'

// UI kit system and themes light/dark
import '@lensflow/ui/assets/themes/system.css'
import '@lensflow/ui/assets/themes/light.css'
import '@lensflow/ui/assets/themes/dark.css'

// UI kit utils
import '@lensflow/ui/assets/stack.css'

// UI kit styles of components that are used in the app
import '@lensflow/ui/assets/Background.css'
import '@lensflow/ui/assets/Button.css'
import '@lensflow/ui/assets/Card.css'
import '@lensflow/ui/assets/Checkbox.css'
import '@lensflow/ui/assets/CopyTextField.css'
import '@lensflow/ui/assets/Dropdown.css'
import '@lensflow/ui/assets/Firework.css'
import '@lensflow/ui/assets/Hr.css'
import '@lensflow/ui/assets/Input.css'
import '@lensflow/ui/assets/Modal.css'
import '@lensflow/ui/assets/OTPCodeInput.css'
import '@lensflow/ui/assets/Popover.css'
import '@lensflow/ui/assets/RadioGroup.css'
import '@lensflow/ui/assets/Scrollable.css'
import '@lensflow/ui/assets/StatusCard.css'
import '@lensflow/ui/assets/TagsMultiSelect.css'
import '@lensflow/ui/assets/Textarea.css'
import '@lensflow/ui/assets/Toast.css'
import '@lensflow/ui/assets/Tooltip.css'
import '@lensflow/ui/assets/Tags.css'

export const router = createBrowserRouter(routes)

// to avoid umami errors for local development
if (process.env.NODE_ENV === 'development' && isBrowserEnvironment()) {
	window.umami = {
		track: fn.noop,
	}
}

const ThemedApp = () => {
	const { theme } = useContext(ThemeContext)

	return (
		<ThemeProvider
			theme={(() => {
				switch (theme) {
					case 'dark':
						return themeDark
					case 'light':
					default:
						return themeLight
				}
			})()}
		>
			<Tooltip.Provider>
				<RouterProvider router={router} />
			</Tooltip.Provider>
			<ToastProvider />
		</ThemeProvider>
	)
}

const App = () => {
	config.viewport.useViewportConfig()

	const { i18n } = useTranslation()
	useSSR(i18n.store.data, i18n.language)

	return (
		<React.StrictMode>
			<DehydratedStateContext.Provider value={{ dehydratedState: window.__REACT_QUERY_STATE__ }}>
				<HelmetProvider>
					<ThemeContextProvider defaultTheme="light">
						<ThemedApp />
					</ThemeContextProvider>
				</HelmetProvider>
			</DehydratedStateContext.Provider>
		</React.StrictMode>
	)
}

const appTag = document.getElementById('app') as HTMLDivElement

console.log('[SSR]: ', process.env.SSR_ENABLED)

if (process.env.SSR_ENABLED === 'true') {
	hydrateRoot(appTag, <App />)
} else {
	createRoot(appTag).render(<App />)
}
