import { useState, useContext } from 'react'
import { httpLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider, Hydrate, isServer } from '@tanstack/react-query'
import type { PropsWithChildren } from 'react'
import type { AxiosError } from 'axios'

import type { AppRouter } from '@lensflow/server'

import { DehydratedStateContext } from 'app/context'
import { isBrowserEnvironment } from 'app/utils'

const trpc = createTRPCReact<AppRouter>()

const makeQueryClient = () => {
	return new QueryClient({
		defaultOptions: {
			queries: {
				// With SSR, we usually want to set some default staleTime
				// above 0 to avoid refetching immediately on the client
				staleTime: 60 * 1000,
				cacheTime: 0, // We don't want to deal with react-query cache
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
				refetchInterval: false,
				refetchOnMount: false,
				retry: (count, err: unknown) => {
					// Do not retry before 422 response and retry other requests only once
					const stopOnCount = count >= 1
					const noRepeatCodes = ((err as AxiosError).response?.status || 0) < 422
					return !(stopOnCount || noRepeatCodes)
				},
			},
		},
	})
}

let browserQueryClient: QueryClient | undefined = undefined

const getQueryClient = () => {
	if (isServer) {
		// Server: always make a new query client
		return makeQueryClient()
	} else {
		// Browser: make a new query client if we don't already have one
		// This is very important, so we don't re-make a new client if React
		// suspends during the initial render. This may not be needed if we
		// have a suspense boundary BELOW the creation of the query client
		if (!browserQueryClient) browserQueryClient = makeQueryClient()
		return browserQueryClient
	}
}

let trpcQueryClient = getQueryClient()

export const init = () => trpc

export const TRPCProvider = ({ children }: PropsWithChildren) => {
	const { dehydratedState } = useContext(DehydratedStateContext)
	trpcQueryClient = getQueryClient()

	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				httpLink({
					url: process.env.LF_TRPC_SERVER_URL,
					async headers() {
						return {
							// authorization: `Bearer ${getAuthCookie()}`,
						}
					},
				}),
			],
		}),
	)

	return (
		<trpc.Provider client={trpcClient} queryClient={trpcQueryClient} ssrState="mounted">
			<QueryClientProvider client={trpcQueryClient}>
				<Hydrate state={dehydratedState || (isBrowserEnvironment() ? window.__REACT_QUERY_STATE__ : null)}>
					{children}
				</Hydrate>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</trpc.Provider>
	)
}
