import { isBrowserEnvironment } from 'app/utils'

type Category = 'common' | 'waitlist' | 'feedback'

const trackEvent = (category: Category, name: string, params: Record<string, string | number | boolean>) => {
	if (isBrowserEnvironment()) {
		window.umami.track(`${category}_${name}`, params)
	}
}

export const CommonEvents = {
	themeChanged: (args: { theme: 'light' | 'dark' }) => trackEvent('common', 'theme_changed', { theme: args.theme }),
	languageChanged: (args: { language: 'en' | 'de' }) =>
		trackEvent('common', 'language_changed', { language: args.language }),
}

export const WaitlistEvents = {
	joinNowClicked: () => trackEvent('waitlist', 'join_no_clicked', {}),
	emailInputFocused: () => trackEvent('waitlist', 'email_input_focused', {}),
	userApplied: () => trackEvent('waitlist', 'user_applied_for_waitlist', {}),
	userFailedToApply: () => trackEvent('waitlist', 'user_failed_to_applied_for_waitlist', {}),
	userAlreadyApplied: () => trackEvent('waitlist', 'user_already_applied_for_waitlist', {}),
	successToastClicked: (args: { closeButton: boolean }) =>
		trackEvent('waitlist', 'success_toast_clicked', { closeButton: args.closeButton }),
	failedToastClicked: (args: { closeButton: boolean }) =>
		trackEvent('waitlist', 'failed_toast_clicked', { closeButton: args.closeButton }),
}

export const FeedbackEvents = {
	companySizeSelected: ({ companySize }: { companySize: string }) =>
		trackEvent('feedback', 'company_size_selected', { companySize }),
	companyRoleSelected: ({ companyRole }: { companyRole: string }) =>
		trackEvent('feedback', 'company_role_selected', { companyRole }),
	companyTypeSelected: ({ companyType }: { companyType: string }) =>
		trackEvent('feedback', 'company_type_selected', { companyType }),
	analyticToolSelected: ({ analyticTool }: { analyticTool: string }) =>
		trackEvent('feedback', 'analytic_tools_selected', { analyticTool }),
	commentSubmitted: () => trackEvent('feedback', 'comment_submitted', {}),
	emptyFeedbackFormSubmitted: () => trackEvent('feedback', 'empty_form_submitted', {}),
	feedbackSubmitted: () => trackEvent('feedback', 'submitted', {}),
}

export const init = () => ({ CommonEvents, WaitlistEvents, FeedbackEvents })
