import { useEffect } from 'react'

import { isBrowserEnvironment } from 'app/utils'

const appHeight = (): void => {
	if (isBrowserEnvironment()) {
		const doc = document.documentElement
		doc.style.setProperty('--app-height', `${window.innerHeight}px`)
	}
}

const useViewportConfig = () => {
	useEffect(() => {
		if (isBrowserEnvironment()) {
			appHeight()
			window.addEventListener('resize', appHeight)
			window.addEventListener('orientationchange', appHeight)
			return () => {
				window.removeEventListener('resize', appHeight)
				window.removeEventListener('orientationchange', appHeight)
			}
		}
		return () => null
	}, [])
}

export const init = () => ({ useViewportConfig })
