import localforage from 'localforage'

const localforageInstance = localforage.createInstance({
	version: 1.0,
	storeName: 'lensflow',
})

type LocalForageItems = {
	test: 'test'
}

const getItem = <K extends keyof LocalForageItems>(key: K): Promise<LocalForageItems[K] | null> => {
	return localforageInstance.getItem(key).then(value => value as LocalForageItems[K] | null)
}

const setItem = <K extends keyof LocalForageItems>(key: K, value: LocalForageItems[K]) => {
	return localforageInstance.setItem(key, value)
}

const removeItem = (key: keyof LocalForageItems) => {
	return localforageInstance.removeItem(key)
}

export const localForageTyped = {
	getItem,
	setItem,
	removeItem,
}
