import { useEffect, useState, createContext } from 'react'
import type { PropsWithChildren } from 'react'

import { fn } from '@lensflow/ui'

import { config } from 'app/config'
import type { ThemeVariant } from 'app/resources'

type ToggleCurrentThemeArgs = {
	onChange?: (theme: ThemeVariant) => void
}

export const ThemeContext = createContext<{
	theme: ThemeVariant
	toggleCurrentTheme: (args: ToggleCurrentThemeArgs) => void
}>({
	theme: 'light',
	toggleCurrentTheme: fn.noop,
})

type Props = PropsWithChildren<{
	defaultTheme: ThemeVariant
}>

export const ThemeContextProvider = ({ children, defaultTheme }: Props) => {
	const appliedTheme = config.cookies.getCookie('theme') || defaultTheme || 'light'
	const [currentTheme, setCurrentTheme] = useState(appliedTheme)

	useEffect(() => {
		config.cookies.setCookie('theme', currentTheme)
	}, [currentTheme])

	const toggleCurrentTheme = ({ onChange = fn.noop }: ToggleCurrentThemeArgs = {}) => {
		switch (currentTheme) {
			case 'dark':
				setCurrentTheme('light')
				config.cookies.setCookie('theme', 'light')
				onChange('light')
				break
			case 'light':
			default:
				setCurrentTheme('dark')
				config.cookies.setCookie('theme', 'dark')
				onChange('dark')
		}
	}

	return <ThemeContext.Provider value={{ theme: currentTheme, toggleCurrentTheme }}>{children}</ThemeContext.Provider>
}
