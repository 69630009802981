import { init as initLocalization } from './localization'
import { init as initAnalytics } from './analytics'
// import { init as initSupabase } from './supabase'
import { init as initViewport } from './viewport'
import { init as initCaptcha } from './captcha'
import { init as initSentry } from './sentry'
import { init as initTRPC } from './trpc'

import { cookiesTyped as cookies } from './cookies'
import { localStorageTyped as localstorage } from './localstorage'
import { localForageTyped as localforage } from './localforage'

import * as constants from './constants'

export { TRPCProvider } from './trpc'
export type { Cookies } from './cookies'

const init = () => {
	initSentry()

	return {
		cookies,
		constants,
		localforage,
		localstorage,
		trpc: initTRPC(),
		captcha: initCaptcha(),
		// FIX: Uncomment this and config/network.ts when Supabase is enabled
		// supabase: initSupabase(),
		viewport: initViewport(),
		analytics: initAnalytics(),
		localization: initLocalization(),
	}
}

export const config = init()
export const trpc = config.trpc
