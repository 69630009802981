import { Outlet } from 'react-router-dom'

import { TRPCProvider } from 'app/config'

export const WithTRPC = () => {
	return (
		<TRPCProvider>
			<Outlet />
		</TRPCProvider>
	)
}
