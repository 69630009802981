import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import type { UseTranslationOptions } from 'react-i18next'
import type { InitOptions } from 'i18next'

export const defaultLanguage = 'en'

export const supportedLanguages = { en: 'en', de: 'de' }
export const supportedLanguagesList = ['en', 'de'] as const

export const supportedNamespaces = ['common', 'notification', 'aria'] as const

export type SupportedLanguages = (typeof supportedLanguagesList)[number]
export type SupportedNamespaces = (typeof supportedNamespaces)[number]

export const i18NextCommonConfig: InitOptions = {
	preload: false,
	load: 'currentOnly' as const, // it still loads current one AND default one but not the rest if available
	fallbackLng: defaultLanguage,
	supportedLngs: supportedLanguagesList,
	returnNull: false,
	returnEmptyString: false,
	ns: ['common'], // 'notifications', 'aria'
	defaultNS: 'common',
}

type ClientConfig = {
	react: UseTranslationOptions<''>
	backend: ChainedBackendOptions
}

export const i18NextClientConfig: ClientConfig = {
	...i18NextCommonConfig,
	backend: {
		backends: [
			resourcesToBackend((lang: SupportedLanguages, ns: SupportedNamespaces) => {
				return fetch(
					process.env.NODE_ENV === 'development' || process.env.LF_ASSETS_SERVED_FROM_LOCAL === 'true'
						? `/locales/${lang}/${ns}.json`
						: `https://${process.env.LF_ASSETS_DOMAIN}/${process.env.LF_LAST_RELEASE}/locales/${lang}/${ns}.json`,
				).then(response => response.json())
			}),
		],
	},
	react: {
		useSuspense: false,
	},
}

export const init = () => {
	i18n.use(LanguageDetector).use(initReactI18next).use(ChainedBackend).init(i18NextClientConfig).catch(console.error)
	return {
		defaultLanguage,
		supportedLanguages,
		supportedLanguagesList,
		config: i18NextCommonConfig,
	}
}

export default i18n
